<template>
  <main class="form-signin text-center">
    <form @submit.prevent="submitForm">
      <img
        class="d-block mx-auto mb-4"
        src="@/assets/logo.png"
        alt="Logo Taekwondo Indonesia"
        width="128"
        height="128"
      />
      <h1 class="h3 mb-3 fw-normal">Taekwondo Indonesia</h1>

      <div class="alert alert-danger" role="alert" v-if="errorMessage">
        {{ errorMessage }}
      </div>

      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          v-model="email"
          :class="{ 'is-invalid': $v.email.$error }"
          placeholder="contoh@xtcindonesia.org"
        />
        <label for="floatingInput">Email</label>
        <div v-if="$v.email.$error" class="invalid-feedback">
          <span v-if="!$v.email.required">Email harus di isi.</span>
          <span v-if="!$v.email.email">Format email harus benar.</span>
        </div>
      </div>
      <div class="form-floating">
        <input
          type="password"
          class="form-control"
          v-model="password"
          :class="{ 'is-invalid': $v.password.$error }"
          placeholder="Kata sandi"
        />
        <label for="floatingPassword">Kata sandi</label>
        <div v-if="$v.password.$error" class="invalid-feedback">
          <span v-if="!$v.password.required">Kata sandi harus di isi.</span>
        </div>
      </div>

      <button
        class="w-100 btn btn-lg btn-primary"
        type="submit"
        :disabled="isLoading"
      >
        {{ isLoading ? "Sedang masuk" : "Masuk" }}
      </button>
      <p class="mt-5 mb-3 text-muted">© 1982–2023 Taekwondo Indonesia</p>
    </form>
  </main>
</template>

<script>
import { authenticationService } from "@/services";
import { required, email } from "vuelidate/lib/validators";
import { Role } from "@/helpers";
export default {
  name: "Login",
  created() {
    if (authenticationService.currentUserValue) {
      return this.$router.push("/");
    }
  },
  data() {
    return {
      isLoading: false,
      errorMessage: "",
      email: "",
      password: "",
    };
  },
  methods: {
    async submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;

        /* eslint-disable no-unused-vars */
        authenticationService.login(this.email, this.password).then(
          (user) => {
            switch (user.role) {
              case Role.SuperUser:
                this.$router.push({ name: "admin-dashboard" });
                break;
              case Role.Admin:
                this.$router.push({ name: "admin-dashboard" });
                break;
              case Role.Anggota:
                this.$router.push({ name: "anggota-home" });
                break;
              case Role.PAC:
                this.$router.push({ name: "admin-dashboard" });
                break;
              case Role.DPC:
                this.$router.push({ name: "admin-dashboard" });
                break;
              case Role.DPD:
                this.$router.push({ name: "admin-dashboard" });
                break;
              case Role.DPP:
                this.$router.push({ name: "admin-dashboard" });
                break;
              default:
                break;
            }
            // this.$router.push(this.returnUrl);
          },
          (error) => {
            this.errorMessage =
              (error.response && error.response.data.message) || error.message;
          }
        );

        this.isLoading = false;
      }
    },
  },
  validations: {
    email: {
      email,
      required,
    },
    password: {
      required,
    },
  },
};
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
